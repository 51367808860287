<template>
    <div>
        <van-nav-bar
            v-if="routeMeta.back"
            :title="''"
            :left-text="routeMeta.back ? '返回' :''"
            :left-arrow="routeMeta.back? true:false"
            @click-left="routeMeta.back? onClickLeft() : ''"/>
        <router-view @closeShareDialog="closeShareDialog" :class="routeModel ? 'route-navs' : ''" />
        <van-tabbar
            class="vans-bottom"
            v-if="routeModel"
            @change="changeNav"
            route
            active-color="#ee0a24"
            inactive-color="#000"
            fixed
            placeholder>
            <van-tabbar-item class="van-items" replace name="index" to="/index"><span class="nav-icons ni1"></span>首页</van-tabbar-item>
            <van-tabbar-item class="van-items" replace name="Policy" to="/policy"><span class="nav-icons ni2"></span>政策</van-tabbar-item>
            <van-tabbar-item class="van-items" replace name="Service" to="/service"><span class="nav-icons ni3"></span>服务</van-tabbar-item>
            <van-tabbar-item class="van-items" replace name="Mine" to="/mine"><span class="nav-icons ni4"></span>我的</van-tabbar-item>
        </van-tabbar>
        <div class="share-watch" v-if="shareDialog">
            <div class="wa-con">
                <span class="close-dial" @click="shareDialog=false"><van-icon name="close" /></span>
                <img src="../assets/images/share-watch.png" />
                <div class="row">扫描二维码, 添加我的企业微信</div>
            </div>
        </div>

        <!-- <helpCenter ref="serviceDialog" isFromType="2" v-if="userAllInfo.businessManagerPhone" :userAllInfo="userAllInfo"></helpCenter> -->
    </div>
</template>

<script>
// import helpCenter from '@/components/HelpCenter'
export default {
    components: {
        // helpCenter
    },
    data() {
        return {
            shareDialog:false,
            // userAllInfo:{
            //   businessManagerTime:'',
            //   businessManagerPhone:''
            // },
            navesNames:['index','Policy','Service','Mine','Index'],
            activeRoute:'',
            routeModel:true,
            routeMeta:{
                title:'',
                back:'',
            },
            logQuery:{}
        };
    },
    watch:{
        '$route': {
			handler(val,oval) {
                let backUrl = JSON.parse(JSON.stringify(val.meta));
                if(val.meta.toJumpUrl){
                    this.logQuery = oval.query;
                    // if(val.query.from && !backUrl.back){
                    //     backUrl.back = '/'+oval.query.from;
                    // }
                }
                this.$nextTick(()=>{
                    this.routeMeta.back = backUrl&&backUrl.back ? backUrl.back : '';
                    this.routeMeta.title = val.meta&&val.meta.title ? val.meta.title : '';
                    this.$nextTick(()=>{
                        if(this.navesNames.includes(val.name)){
                            this.routeModel = true;
                        }else {
                            this.routeModel = false;
                        }
                    })
                })
            }
        }
    },
    created() {
        let backUrl = this.$route.meta;
        if(this.$route.meta.toJumpUrl){
            this.logQuery = this.$route.query;
            // if(this.$route.query.from){
            //     backUrl.back = '/'+this.$route.query.from;
            // }
        }
        this.$nextTick(()=>{
            this.routeMeta.back = backUrl&&backUrl.back ? backUrl.back : '';
            this.routeMeta.title = this.$route.meta&&this.$route.meta.title ? this.$route.meta.title : '';
            this.$nextTick(()=>{
                if(this.navesNames.includes(this.$route.name)){
                    this.routeModel = true;
                }else {
                    this.routeModel = false;
                }
            })    
        })
    },
    mounted() {
    },
    computed: {},
    methods: {
        closeShareDialog(){
            this.shareDialog = true;
        },
        // openService(){
        //   this.$refs['serviceDialog'].openDialog();
        // },
        changeNav(inx){
            this.activeRoute = inx;
        },
        onClickLeft(){
            if(this.routeMeta.back == '/mine'){
                this.logQuery = {};
            }
            this.$router.push({path:this.routeMeta.back,query:this.logQuery});
        }
    },
};
</script>
<style lang="less">
.vans-bottom { 
    z-index: 999; position: fixed; bottom:0px; left:0px;
}
.route-navs {
    margin-bottom: 70px;
}
.van-items { text-align: center;}
.nav-icons {
    display: block;
    width: 22px; height: 32px; overflow: hidden;
    background-repeat: no-repeat; background-size: 22px auto;
    background-position: center center;
}
.ni1 { background-image:url('../assets/images/nav/ni1.png');}
.ni2 { background-image:url('../assets/images/nav/ni2.png');}
.ni3 { background-image:url('../assets/images/nav/ni3.png');}
.ni4 { background-image:url('../assets/images/nav/ni4.png');}

.van-tabbar-item--active {
    .ni1 { background-image:url('../assets/images/nav/ni1-on.png');}
    .ni2 { background-image:url('../assets/images/nav/ni2-on.png');}
    .ni3 { background-image:url('../assets/images/nav/ni3-on.png');}
    .ni4 { background-image:url('../assets/images/nav/ni4-on.png');}
    .van-tabbar-item__text { color: #0062FF;}
}
</style>
